import React from "react";
import StretchedLink from "../utils/stretchedLink";
import { H2, Small } from "./UI/typography";

const PostLink = ({ data }) => {
  const { title, slug, excerpt, date } = data
  const _date = new Date(date);
  return (
    <div>
      <Small>{_date.toLocaleDateString()}</Small>
      <H2>{title}</H2>
      <div dangerouslySetInnerHTML={{__html: excerpt}} />
      <StretchedLink to={`/news/${slug}`} title={title} />
    </div>
  )
}

export default PostLink