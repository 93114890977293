import Loadable from "@loadable/component"
import React from "react"
import PostLink from "./postLink"
const Grid = Loadable(() => import("./UI/grid"))

const PostsList = ({ posts }) => (
  <Grid data={posts}>
    <PostLink />
  </Grid>
)

export default PostsList
