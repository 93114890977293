import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-grid-system"
import Breadcrumbs from "../components/breadcrumbs"
import PostsList from "../components/postsList"
import SEO from "../components/seo"
import { H1 } from "../components/UI/typography"

export default ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs }
  } = pageContext
  return (
    <Container>
      <SEO title="Новости" />
      <Breadcrumbs crumbs={crumbs} label={"Новости"} />
      <H1>Новости</H1>
      <PostsList
        posts={data.allWordpressPost.edges.map(({ node }) => ({
          key: node.id,
          id: node.id,
          title: node.title,
          slug: node.slug,
          excerpt: node.excerpt,
          date: node.date,
        }))}
      />
    </Container>
  )
}

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          title
          slug
          excerpt
          date
        }
      }
    }
  }
`
